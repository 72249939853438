import weareailogo from '../assets/img/home/weareai.jpg';
export const Work = [
    
{
    year:'2024 - Currently',
    company:'WeAreAi',
    companylogo:weareailogo,
    companylogoalt:'WeAreAiLogo',
    position:'Web Developer'
},
];