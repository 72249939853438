import wordpress from '../assets/img/skills/wordpress.png'
import ecommerce from '../assets/img/skills/e-commerce.png'
import trio from '../assets/img/skills/htmlcssjs.png'
import django from '../assets/img/skills/djangoo.png'
import seo from '../assets/img/skills/seo.png'
import react from '../assets/img/skills/react.png'
export const Skills = [
    
{
    name:'Wordpress ',
    logo:wordpress,
    alt:'wordpress logo',
},
{
    name:'E-Commerce ',
    logo:ecommerce,
    alt:'E-Commerce logo',
},

{
    name:'Django Framework',
    logo:django,
    alt:'Django logo',
},
{
    name:'HTML CSS JS',
    logo:trio,
    alt:'HTML CSS AND JAVASCRIPT logo',
},

{
    name:'React',
    logo:react,
    alt:' React logo',
},

];