import camplogo from "../assets/img/camplogo.png"
import ficicompany from "../assets/img/ficicompanylogo.png"
import konobavotra from "../assets/img/konobavotralogo.png"
export const Projects = [
    
{
    photo:camplogo,
    alt:'Camp Ostros Logo',
    client:'NVO Camp Ostros',
    category:'Wordpress Development',
    service:'Web Development',
    overview:'The Camp Ostros website is a welcoming and informative platform showcasing the camps offerings. It features a vibrant homepage, an "About Us" section detailing the camp’s mission. The site also includes a section on events, a gallery of camp photos,events of the camp and a lot more. With a focus on simplicity and user experience, the website allows visitors to explore, learn about, and learn more about  Camp Ostros effortlessly.',
    link:'https://campostros.me/',
    challanges:'Creating the Camp Ostros website involved several challenges, such as ensuring consistent design while maintaining a balance between aesthetics and functionality. Making the site fully mobile-responsive required extra testing and adjustments. Organizing content to provide clear, concise information without overwhelming visitors was also a key challenge. Additionally, optimizing high-quality images for fast loading while preserving their visual appeal was essential for a smooth user experience. Finally, integrating interactive elements, such as activity descriptions and an engaging gallery, required thoughtful design to keep users engaged.',
    conclusion:'In conclusion, despite the challenges faced during its creation, the Camp Ostros website successfully combines visual appeal, clear organization, and functionality. Through careful attention to design consistency, mobile responsiveness, and content optimization, the site provides a seamless and engaging user experience. The final result is a platform that effectively showcases the beauty and offerings of Camp Ostros, inviting visitors to explore and learn more about the camp’s unique experiences.'
    
},
{
    photo:ficicompany,
    alt:'Fici Company Logo',
    client:'Fici Company',
    category:'E-Commerce',
    service:'E-Commerce Development',
    overview:'The Fici Company website is a modern e-commerce platform specializing in selling shoes and accessories. It features a clean, user-friendly interface that allows visitors to browse a wide range of stylish footwear and accessories. Key sections include product categories, a detailed product page with images and descriptions, and a seamless shopping cart and checkout process. The site also offers filters for size, style, and price, making it easy for users to find what they need. Designed for a smooth shopping experience, the Fici Company website is fully responsive and optimized for both desktop and mobile use.',
    link:'https://ficicompany.me/',
    challanges:'Creating the Fici Company e-commerce website involved several challenges, including ensuring seamless navigation and balancing aesthetics with functionality to enhance user experience. Optimizing product images for fast loading without sacrificing quality was essential, as visual appeal is crucial in online retail. Additionally, integrating secure payment options and a reliable shopping cart system required thorough testing for a smooth checkout process. Implementing effective search and filter functionalities and ensuring full responsiveness across devices also posed challenges, but the end result is an engaging and user-friendly online shopping experience.',
    conclusion:'In conclusion, the Fici Company e-commerce website effectively addresses the challenges of creating a user-friendly and visually appealing online shopping platform. By focusing on seamless navigation, high-quality visuals, and secure payment options, the site offers customers an engaging shopping experience. The final product not only showcases the brands stylish footwear and accessories but also ensures ease of use across all devices, positioning Fici Company for success in the competitive e-commerce market.'
    
},
{
    photo:konobavotra,
    alt:'Konoba Votra Logo',
    client:'Konoba Votra',
    category:'Qr-Menu',
    service:'Qr-Menu',
    overview:'The Konoba Votra Restaurant QR menu website offers a modern and convenient way for diners to access the restaurant’s menu. Designed for ease of use, the site features a clean layout that allows customers to quickly browse through a variety of dishes, including appetizers, main courses, and desserts. Each menu item is accompanied by mouth-watering images and detailed descriptions, enhancing the dining experience. The QR code integration enables guests to view the menu on their mobile devices, making it easy to order and explore options without the need for physical menus.',
    link:'https://konobavotra.com/',
    challanges:'Building the Konoba Votra Restaurant QR menu website presented several challenges. Ensuring a user-friendly interface was crucial, as diners needed to navigate the menu easily on mobile devices. Optimizing images for quick loading times while maintaining quality was essential for an appealing presentation. Additionally, integrating the QR code functionality required thorough testing to ensure seamless access for all customers. Finally, keeping the menu content updated and managing any changes to dishes or prices posed ongoing challenges.',
    conclusion:'In conclusion, the Konoba Votra Restaurant QR menu website effectively addresses the challenges of creating a user-friendly and visually appealing digital menu. By focusing on mobile accessibility, optimized images, and easy QR code integration, the site enhances the overall dining experience for guests. The result is a convenient and modern solution that allows customers to explore the menu effortlessly, reflecting the restaurants commitment to quality and service.'    
},
];