import './App.css';
import './assets/css/bootstrap.min.css';
import './assets/fontawsome/css/all.min.css';
import './assets/fontawsome/css/fontawesome.min.css';
import './assets/css/slick.css';
import './assets/css/magnific-popup.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import { useState } from 'react';
import { Navbar } from './components/Navbar';
import { Home } from './components/Home';
import { Footer } from './components/Footer';
import { About } from './components/About';
import { ServicePage } from './components/Services';
import { Works } from './components/Works';
import { Contact } from './components/Contact';

function App() {
  
  
  const [currentPage, setCurrentPage] = useState('home'); 

  const renderPage = () => {
    if (currentPage === 'home') {
        return <Home setcurrentpage={setCurrentPage} />;
    } else if (currentPage === 'about') {
        return <About />;
    } else if (currentPage === 'services') {
        return <ServicePage />;
    } else if (currentPage === 'works') {
        return <Works />;
    } else if (currentPage === 'contact') {
        return <Contact />;
    } else {
      
        return <Home  />;
    }
};



  return (
    <div id="page-content">
      <header className="header-area">
        <Navbar setCurrentPage={setCurrentPage} thecurrentPage={currentPage}/> 
      </header>
      {renderPage()} 
      <Footer />
    </div>
  );
}

export default App;
