import ficicompnaylogo from "../assets/img/ficicompanylogo.png"
import campostroslogo from "../assets/img/camplogo.png"
import konobavotra from "../assets/img/konobavotralogo.png"

export const Websites = [
    
{
    name:'Camp Ostros',
    logo:campostroslogo,
    alt:'Camp Ostros ',
    url:'https://campostros.me/',
    category:"Wordpress  Development"
},
{
    name:'Fici Company',
    logo:ficicompnaylogo,
    alt:'Fici Company ',
    url:'https://ficicompany.me/',
    category:"E-Commerce"
},
{
    name:'Konoba Votra ',
    logo:konobavotra,
    alt:'Qr Menu ',
    url:'https://dzenisberjasevic.tech/konobavotra/',
    category:"QR Menu"
},






];