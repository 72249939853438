import { Background } from "./Background";
import { Profile } from "./Profile";
import {Skills} from "../data/Skills";
import { Websites } from "../data/Websites";
import circletext from "../assets/img/circle-text.svg"
import circletextlight from "../assets/img/circle-text-light.svg"
import { Faq } from "./FAQ";
import {Ratings} from "../data/Ratings";
import {Worktogether} from "./WorkTogetherSlider";


export const About = () =>{
    return(
        
    <main>

      <section class="content-box-area mt-4">
        <div class="container">
          <div class="row g-4">

           
                <Profile/>
              
            
            <div  id="theabout" class="col-xl-8">
              <div class="card content-box-card">
                <div class="card-body">
                  <div class="top-info">
                    <div class="text">
                      <h1 class="main-title">Hi, This Is <span>Xhenis Berjashi</span> 👋</h1>
                      <p> <b>Web Developer</b> </p>
                    </div>
                    <div class="available-btn">
                      <span><i class="fas fa-circle"></i> Available For Hire</span>
                    </div>
                  </div>
                  <div class="counter-area">
                    <div class="counter">
                      <div class="counter-item">
                        <h3 class="number">2+</h3>
                        <p class="subtitle">Years of Experience</p>
                      </div>
                      <div class="counter-item">
                        <h3 class="number">20+</h3>
                        <p class="subtitle">Project Completed
                        </p>
                      </div>
                      <div class="counter-item">
                        <h3 class="number">5+</h3>
                        <p class="subtitle">Happy Clients
                        </p>
                      </div>
                    </div>
                    <div class="circle-area">
                      <div class="circle-text">
                        <img class="circle-image" src={circletext} alt="circle-text"/>
                        <img class="circle-image circle-image-light d-none"
                          src={circletextlight} alt="circle-text"/>
                        <span class="arrow-down">
                          <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M20 5V35" stroke="white" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" />
                            <path d="M15 30L20 35L25 30" stroke="white" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="working-with-area">
                    <h2 class="main-common-title">Skills
                    </h2>
                    <div class="working-with-main">
                     {Skills.map((item,index)=>(
 <div class="items" key={index}>
 <img src={item.logo} alt={item.alt}/>
</div>
                     ))}
                      
                  </div>
                  </div>
                  <div class="client-feedback">
                    <h2 class="main-common-title">
                    </h2>
                    <div class="row client-feedback-slider">
                      
                    {Ratings.map((feedback,index)=>(
                       <div class="col-lg-6" key={index}>
                       <div class="feedback-item">
                         <div class="feedback-top-info">
                           <div class="rating">
                             <i class="fas fa-star"></i>
                             <i class="fas fa-star"></i>
                             <i class="fas fa-star"></i>
                             <i class="fas fa-star"></i>
                             <i class="fas fa-star"></i>
                           </div>
                           <div class="website">
                             <a href={feedback.link}>{feedback.link}
                               <svg class="arrow-up" width="14" height="15" viewBox="0 0 14 15" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                 <path d="M9.91634 4.5835L4.08301 10.4168" stroke-linecap="round"
                                   stroke-linejoin="round" />
                                 <path d="M4.66699 4.5835H9.91699V9.8335" stroke-linecap="round"
                                   stroke-linejoin="round" />
                               </svg>
                             </a>
                           </div>
                         </div>
                         <div class="details">
                           <p>
                             {feedback.description}
                           </p>
                         </div>
                         <div class="designation">
                           <p><span>{feedback.person}</span>  {feedback.position}</p>
                         </div>
                       </div>
                     </div>
                    ))}
                   
                    </div>
                  </div>
                  <div class="awards-recognitions">
                    <h2 class="main-common-title">Websites
                    </h2>
                    <div class="awards-recognitions-main">
                      <ul class="list-unstyled">
                       {Websites.map((site,index)=>(
                         <li key={index}>
                         <a href={site.url} class="d-block w-100">
                           <div class="awards-item">
                             <div class="award-name">
                               <div class="icon">
                                 <img src={site.logo} alt={site.alt}/>
                               </div>
                               <div class="text">
                                 <h4 class="title">{site.name}</h4>
                                 <p class="year">{site.category}</p>
                               </div>
                             </div>
                             <div class="winner-tag">
                               <h4 class="title">
                                 <svg class="icon" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                   <path
                                     d="M6 9C6 10.5913 6.63214 12.1174 7.75736 13.2426C8.88258 14.3679 10.4087 15 12 15C13.5913 15 15.1174 14.3679 16.2426 13.2426C17.3679 12.1174 18 10.5913 18 9C18 7.4087 17.3679 5.88258 16.2426 4.75736C15.1174 3.63214 13.5913 3 12 3C10.4087 3 8.88258 3.63214 7.75736 4.75736C6.63214 5.88258 6 7.4087 6 9Z"
                                     stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                   <path d="M12 15L15.4 20.89L16.998 17.657L20.596 17.889L17.196 12" stroke-width="1.5"
                                     stroke-linecap="round" stroke-linejoin="round" />
                                   <path d="M6.80234 12L3.40234 17.89L7.00034 17.657L8.59834 20.889L11.9983 15"
                                     stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                 </svg>
                               
                               </h4>
                             </div>
                             <div class="project-btn">
                               <span>
                                 Visit Site 
                                 <svg class="arrow-up" width="14" height="15" viewBox="0 0 14 15" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                   <path d="M9.91634 4.5835L4.08301 10.4168" stroke-linecap="round"
                                     stroke-linejoin="round" />
                                   <path d="M4.66699 4.5835H9.91699V9.8335" stroke-linecap="round"
                                     stroke-linejoin="round" />
                                 </svg>
                               </span>
                             </div>
                           </div>
                         </a>
                       </li>
                       ))}
                      
                      </ul>
                    </div>
                  </div>
                  <Faq/>
                  <Worktogether/>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      
     <Background/>
     
    </main>
   
    );
}