
export const QA = [
    
{
    question:'What kind of web development services do you offer? ',
      answer:'I offer a wide range of web development services, including front-end development (HTML, CSS, JavaScript, React, etc.), back-end development (Django Framework),Wordpress and e-commerce integration. I also handle website design, performance optimization, and SEO best practices. Whether you need a business site, portfolio, blog,Online Qr Menu or web application, I can help.'
},
{
    question:'How long does it take to build a website? ',
    answer:'The time required depends on the complexity of the website and specific project requirements. A basic website can typically take 2-4 weeks, while a larger, more customized web application may take several months. After an initial consultation, I can provide a more accurate timeline based on your project.'
},
{
    question:'How much do you charge for a website project?',
    answer:'Pricing varies depending on the scope, features, and complexity of the project. '
},



{
    question:'Do you provide ongoing maintenance and support after the website is launched?',
    answer:'Yes! I offer ongoing website maintenance and support services, including updates, security patches, performance optimization, and content management. I can also provide training to help you manage your site independently if desired'
},

{
    question:'Will my website be mobile-friendly and responsive? ',
    answer:'Absolutely! Every website I build is designed to be fully responsive, meaning it will look great and work smoothly on all devices, including desktops, tablets, and smartphones.'
},


];