
import { Profile } from './Profile';
import { Work } from '../data/Work'; 
import { Skills } from '../data/Skills';
import { Services } from '../data/Services';
import { Background } from './Background';
import { Projects } from '../data/Projects';

export const Home = ({setcurrentpage}) => {

  const recentProjects=Projects.slice(0,2);

  


 


return(
   
    <main>
      <section class="home-area">
        <div class="container">
          <div class="row g-4">
           <Profile/>
            <div class="col-xl-4">
              <div class="row g-4">
                <div class="col-lg-12">
                  <div class="card">
                    <div class="card-body work-experiance-card">
                      <h3 class="card-title">Work Experience</h3>
                      <div class="work-experiance-main">
                        <ul class="work-experiance-slider list-unstyled">

                         {Work.map((work)=>(
               <li>
               <div class="date">
                 <p>{work.year}</p>
               </div>
               <div class="info">
                 <div class="icon">
                   <img src={work.companylogo} alt={work.companylogoalt}/>
                 </div>
                 <div class="text">
                   <h4 class="title">{work.company}
                   </h4>
                   <h6 class="subtitle">{work.position}

                   </h6>
                 </div>
               </div>
             </li>
                         )


                         )}
                         
                           
                         
                        </ul>
                        
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="card expertise-card">
                    <div class="card-body">
                      <h3 class="card-title">Skills
                      </h3>
                      <div class="expertise-main mt-24">
                        <div class="row g-3">
                        {Skills.map((skill, index) => (
  <div className="col-xl-4 col-md-4 col-sm-6 col-6" key={index}>
    <div className="expertise-item">
      <div className="image text-center">
        <img src={skill.logo} alt={skill.alt} />
      </div>
      <div className="text">
        <h4 className="title">{skill.name}</h4>
      </div>
    </div>
  </div>
))}
                         
                        
                          
                     
                          
              
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4">
              <div class="card card-projects">
                <div class="card-body">

                  <h3 onClick={()=>setcurrentpage('works')}   class="card-title">Recent Projects <a class="link-btn" href="#">All Projects
                      <svg class="icon" width="20" height="20" viewBox="0 0 20 20" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.16699 10H15.8337" stroke="#4770FF" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M10.833 15L15.833 10" stroke="#4770FF" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M10.833 5L15.833 10" stroke="#4770FF" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round" />
                      </svg>

                    </a></h3>
                  <div class="projects-main mt-24">
                    <div class="row g-4 parent-container">
                    

                    {recentProjects.map((project,index)=>(
   <div class="col-lg-12" key={index}>
   <div class="project-item">
     <div class="image">
       <img className='recent-photo' src={project.photo} alt={project.alt} />
       <a href={project.link}
         class="gallery-popup full-image-preview parent-container">
         <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none"
           stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
           <path d="M10 4.167v11.666M4.167 10h11.666"></path>
         </svg>
       </a>
       <div class="info">
         <span class="category">{project.category}</span>
       </div>
     </div>
   </div>
 </div>

                    ))}
                    </div>
                      </div>
                </div>
              </div>
            </div>
          </div>
          <div class="services-area mt-24">
            <div class="row g-4">
              <div class="col-xl-8">
                <div class="card services-card">
                  <div class="card-body">
                    <h3 onClick={()=>setcurrentpage('services')} class="card-title">Services I Offer
                      <a class="link-btn" href="#"> See All Services
                        <svg class="icon" width="20" height="20" viewBox="0 0 20 20" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path d="M4.16699 10H15.8337" stroke="#4770FF" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round" />
                          <path d="M10.833 15L15.833 10" stroke="#4770FF" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round" />
                          <path d="M10.833 5L15.833 10" stroke="#4770FF" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round" />
                        </svg>

                      </a></h3>
                    <div class="services-main mt-24">
                      <div class="row g-4">
                       {Services.map((service,index)=>(
 <div class="col-md-3 col-sm-6 col-6" key={index}>
 <div class="services-item text-center">
   <div class="image">
     <img src={service.logo} alt={service.alt}/>
   </div>
   <div class="text">
     <h3 class="title">{service.name}</h3>
   </div>
 </div>
</div>
                       ))}
                       
                        
                        
                      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4">
                <div class="card lets-talk-together-card">
                  <div class="card-body">
                    <div class="scrolling-info">
                      <div class="slider-item">
                        <p>
                          Available For Hire 🚀 Crafting Digital Experiences 🎨 Available For Hire 🚀 Crafting Digital
                          Experiences 🎨
                        </p>
                      </div>
                    </div>
                    <h3 class="card-title">Let's👋
                      <span class="d-block">Work Together</span>
                    </h3>
                    <a onClick={()=>setcurrentpage('contact')} class="link-btn" href="#"> Let's Talk
                      <svg class="icon" width="20" height="20" viewBox="0 0 20 20" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.5 11.6665V6.6665H12.5" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M17.5 6.6665L10 14.1665L2.5 6.6665" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    
     <Background/>
   
    </main>
  


  
);
}

