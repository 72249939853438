
export const Ratings = [
    
{
   link:'https://campostros.me/',
   description:'Fantastic job on the website! The design is clean, user-friendly, and exactly what I envisioned. Your attention to detail and responsiveness throughout the project were impressive. Thanks for delivering a high-quality site on time!',
   site:'Camp Ostros',
   person:'CAMP OSTROS CEO',
   position:''

},
{
    link:'https://ficicompany.me/',
    description:'Excellent work on the eCommerce site! The layout is intuitive, and the functionality is smooth, making it easy for users to navigate and shop. Your integration of payment options and seamless checkout process are top-notch.',
    site:'Fici Company',
    person:'FICI COMPANY MANAGER',
    position:''
 
 },
];