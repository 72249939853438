export const Worktogether=()=>{
    return(

<div class="work-together-slider">
                    <div class="slider-main d-flex gap-4 align-items-center">
                      <div class="slider-item">
                        <a >Let's 👋 Work Together</a>
                        <a>Let's 👋 Work Together</a>
                      </div>
                      <div class="slider-item">
                        <a >Let's 👋 Work Together</a>
                        <a >Let's 👋 Work Together</a>
                      </div>
                    </div>
                  </div>




    );
}