import { useState } from "react";
import { Background } from "./Background";
import { Profile } from "./Profile";
import emailjs from "emailjs-com";

export const Contact = () => {
  const [formdata, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''  // Changed comment to message to match the form field name
  });

  const handleChange = (e) => {
    setFormData({
      ...formdata,
      [e.target.name]: e.target.value
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    
    emailjs.sendForm("service_us4df2y", "template_po8vtyf", e.target, "RamBRqCLR7LnakF6g")
      .then((result) => {
        console.log(result.text);
        alert("Email sent successfully!");
      })
      .catch((error) => {
        console.log(error.text);
        alert("Failed to send email.");
      });
  };

  return (
    <main>
      <section className="content-box-area mt-4">
        <div className="container">
          <div className="row g-4">
            <Profile/>
            <div className="col-xl-8">
              <div className="card content-box-card">
                <div className="card-body portfolio-card contact-card">
                  <div className="top-info">
                    <div className="text">
                      <h1  id="contactt" className="main-title">Let's 👋 <span>Work</span> Together</h1>
                     <p>

                     </p>
                    </div>
                  </div>
                  <div className="contact-area">
                    <div className="leave-comments-area">
                      <div className="comments-box">
                        <form id="contact-form" onSubmit={sendEmail}>
                          <div className="row gx-3">
                            <div className="col-md-6">
                              <div className="mb-4">
                                <label className="form-label">Name</label>
                                <input 
                                  name="name" 
                                  required 
                                  type="text" 
                                  className="form-control shadow-none" 
                                  placeholder="Enter your name" 
                                  onChange={handleChange} 
                                  value={formdata.name} 
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-4">
                                <label className="form-label">Email</label>
                                <input 
                                  name="email" 
                                  required 
                                  type="email" 
                                  className="form-control shadow-none" 
                                  placeholder="Enter your email" 
                                  onChange={handleChange} 
                                  value={formdata.email} 
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-4">
                                <label className="form-label">Subject</label>
                                <input 
                                  name="subject" 
                                  required 
                                  type="text" 
                                  className="form-control shadow-none" 
                                  placeholder="Subject" 
                                  onChange={handleChange} 
                                  value={formdata.subject} 
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label className="form-label">Message</label>
                                <textarea 
                                  name="message" 
                                  className="form-control shadow-none" 
                                  rows="4" 
                                  placeholder="Type details about your inquiry" 
                                  onChange={handleChange} 
                                  value={formdata.message}  // Updated this to match the form field name
                                ></textarea>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <button className="submit-btn" type="submit">
                                Send Message
                                <svg className="icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M17.5 11.6665V6.6665H12.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                  <path d="M17.5 6.6665L10 14.1665L2.5 6.6665" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                              </button>
                            </div>
                          </div>
                        </form>
                        <p className="ajax-response mb-0"></p>
                      </div>
                    </div>
                    <div className="contact-map-area">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d191697.95803300928!2d19.125389720050408!3d41.93078423278127!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x134e1afc6e32e755%3A0x3456f11827fb7e3b!2sUlcinj%2C%20Montenegro!5e0!3m2!1sen!2sbd!4v1711832776336!5m2!1sen!2sbd"
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Background/>
    </main>
  );
};
