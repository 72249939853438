import { QA } from "../data/QA";
import plusicon from "../assets/img/icons/plus.svg"
import minusicon from "../assets/img/icons/minus.svg"
import { useState } from "react";

export const Faq = ()=>{


  const [openIndex,setIndex]=useState(null);



  function toogleIndex(index){
    setIndex(openIndex===index ? 'null' : index)
  }


    
    return(
        <div class="frequently-asked-questions">
        <h2 class="main-common-title">Frequently Asked Questions
        </h2>
        <div class="frequently-asked-questions-main">
          <div class="accordion" id="accordionExample">
            {QA.map((qa,index)=>(
                <div class="accordion-item" key={index}>
              <h4 class="accordion-header" id={`heading${index}`}>
                <button class={`accordion-button ${openIndex===index ? '' : 'collapsed'}`} type="button" data-bs-toggle="collapse"
                  data-bs-target={`#collapse${index}`} aria-expanded={openIndex===index} aria-controls="collapseOne" onClick={()=>toogleIndex(index)}>
                  {qa.question}
                  <span class="ms-auto">
                    <span class="icon ms-4">
                      <img class="icon-plus " src={plusicon} alt="plus"/>
                      <img class="icon-minus d-none " src={minusicon} alt="minus"/>
                      
                    </span>
                  </span>
                </button>
              </h4>
              <div id={`collapse ${index}`} class={`accordion-collapse collapse ${openIndex===index ? 'show':''}`} aria-labelledby={`heading${index}`}
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>{qa.answer}</p>
                </div>
              </div>
            </div>
            ))}
           
           
       
          </div>
        </div>
      </div>



    );
}