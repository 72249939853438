import wordpress from '../assets/img/skills/wordpress.png'
import ecommerce from '../assets/img/skills/e-commerce.png'
import web from '../assets/img/services/web.png'
import maintenance from '../assets/img/services/maintenance.png'

export const Services = [
    
{
    name:'Wordpress  Development',
    logo:wordpress,
    alt:'wordpress logo',
},
{
    name:'Custom Website Development',
    logo:web,
    alt:'E-Commerce logo',
},
{
    name:'E-Commerce Development',
    logo:ecommerce,
    alt:'E-Commerce logo',
},

{
    name:'Maintenance',
    logo:maintenance,
    alt:'Django logo',
},




];