import { act, useState } from "react";
import { Background } from "./Background";
import { Profile } from "./Profile";
import { Projects } from "../data/Projects";
import { Worktogether } from "./WorkTogetherSlider";

export const Works = ()=>{

  const [activeProject,setActiveProject]=useState(0);

  function next(){
    const arraylength=Projects.length;
    if(activeProject+1===arraylength){
      setActiveProject(0);
    }
    else{
      setActiveProject((active)=>active+1);
    }
  }

    function previous(){
      const arraylength=Projects.length;
      if(activeProject-1===-1){
        setActiveProject(arraylength-1);
      }
      else{
        setActiveProject((active)=>active-1);
      }
  
  }

    return(

<main>

<section class="content-box-area mt-4">
  <div class="container">
    <div class="row g-4">
     <Profile/>
      <div class="col-xl-8" id="workk">
        <div class="card content-box-card">
          <div class="card-body portfolio-card">
            <div class="portfolio-details-area">
              <div class="main-image">
                <img className="project-photo" src={Projects[activeProject].photo} alt={Projects[activeProject].alt}/>
              </div>
              <div class="portfolio-details-text">
                <div class="short-info">
                  <div class="info-item">
                    <p class="subtitle">Client For:</p>
                    <h4 class="card-title">{Projects[activeProject].client}</h4>
                  </div>
                  <div class="info-item">
                    <p class="subtitle">Services:</p>
                    <h4 class="card-title">{Projects[activeProject].service}</h4>
                    <a href={Projects[activeProject].link} class="website">
                     {Projects[activeProject].link}
                      <svg class="arrow-up" width="14" height="15" viewBox="0 0 14 15" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.91634 4.5835L4.08301 10.4168" stroke-linecap="round" stroke-linejoin="round">
                        </path>
                        <path d="M4.66699 4.5835H9.91699V9.8335" stroke-linecap="round" stroke-linejoin="round">
                        </path>
                      </svg>
                    </a>
                  </div>
                </div>
                <div class="overview">
                  <h4 class="card-title">Overview</h4>
                  <p>
                    {Projects[activeProject].overview}
                  </p>
                 
                  
                </div>
              </div>
             
              <div class="more-info-block">
                <h3 class="more-info-title">Challenges</h3>
                <p>{Projects[activeProject].challanges}</p>
                
              
                

               

                <h3 class="more-info-title">Results/Conclusion:</h3>
                <p>{Projects[activeProject].conclusion}
                </p>
              </div>
              <div class="prev-and-next-btn">
                <a href="#workk" class="btn btn-prev" onClick={previous}>Previous</a>
                <a href="#workk" class="btn btn-next" onClick={next}>Next</a>
              </div>
            </div>

           <Worktogether/>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<Background/>

</main>


    );
}