import shape1 from '../assets/img/bg/banner-shape-1.png';
import object3d1 from '../assets/img/bg/object-3d-1.png';
import object3d2 from '../assets/img/bg/object-3d-2.png';

export const Background = ()=>{
    return(


        <div class="background-shapes">
        <div class="shape-1 common-shape">
          <img src={shape1} alt="banner-shape-1"/>
        </div>
        <div class="shape-2 common-shape">
          <img src={shape1} alt="banner-shape-1"/>
        </div>
        <div class="threed-shape-1 move-with-cursor" data-value="1">
          <img src={object3d1} alt="object-3d-1"/>
        </div>
        <div class="threed-shape-2 move-with-cursor" data-value="1">
          <img src={object3d2} alt="object-3d-2"/>
        </div>
      </div>

    );
}