export const Footer = () =>{
return(


 
  <footer class="footer-area">
    <div class="container">
      <div class="text text-center">
        <p>@ Xhenis Berjashi 2024,</p>
      </div>
    </div>
  </footer>


);
}